import {} from // faClock
// faUser,
// faUserGroup
'@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import RevealDropdown from 'components/base/RevealDropdown';
// import { convertTimestampToDate, convertTimeToHumanTime } from 'helpers/date';
import useNotificationHook from 'hooks/modules/useNotification';
import FeatherIcon from 'feather-icons-react';
import { Dropdown } from 'react-bootstrap';
import { TNotification } from 'types/notification';
import Badge from 'components/base/Badge';
import { useNavigate } from 'react-router-dom';

export interface NotificationItemProps {
  notification: TNotification;
  className?: string;
  type: 'dropdownItem' | 'pageItem';
}

const NotificationItem = ({
  notification,
  className,
  type
}: NotificationItemProps) => {
  const navigate = useNavigate();
  const { updateNotificationSeenStatus } = useNotificationHook();

  const handleRedirect = () => {
    const actionUrl = notification?.action_url || '#';
    navigate('/' + actionUrl);
  };

  const handleSubmit = (notification: TNotification) => {
    const data = { is_seen: !notification?.is_seen };
    updateNotificationSeenStatus(notification?.id || 0, data).catch(
      (e: unknown) => console.log(e)
    );
  };

  return (
    <div
      className={classNames(
        className,
        'py-3 notification-card position-relative',
        {
          unread: !notification.is_seen,
          'px-4 px-lg-6': type === 'pageItem',
          'px-2 px-sm-3': type === 'dropdownItem'
        }
      )}
    >
      <div className="d-flex align-items-center justify-content-between position-relative">
        <div className="d-flex">
          <div
            className={classNames('flex-1', {
              'me-sm-3': type === 'dropdownItem',
              'mt-2 me-2': type === 'pageItem'
            })}
          >
            <div className="d-flex align-items-center justify-content-start fs-9 text-body-emphasis mb-2">
              <h4 onClick={handleRedirect} className="cursor-pointer">
                <span className="me-1 text-body-tertiary fw-bolder text-capitalize">
                  {notification?.notification_type?.split('_').join(' ') || ''}
                </span>
              </h4>

              {notification?.priority == 1 && (
                <FeatherIcon
                  className="text-warning ms-2"
                  icon="alert-triangle"
                  style={{ width: '14px', height: '14px' }}
                />
              )}
              {notification?.priority == 2 && (
                <FeatherIcon
                  className="text-danger ms-2"
                  icon="alert-triangle"
                  style={{ width: '14px', height: '14px' }}
                />
              )}

              <Badge
                pill
                bg="secondary"
                variant="phoenix"
                className="border border-warning p-1 ms-2 text-warning bg-white border-opacity-25"
              >
                {notification?.title}
              </Badge>
            </div>

            <p onClick={handleRedirect} className="cursor-pointer fw-bolder">
              {notification?.body}
            </p>

            <p className="d-flex gap-2 w-100 text-body-secondary fs-9 mb-0">
              {/* <div className="d-flex align-items-baseline">
                {notification?.status == 1 && (
                  <>
                    <FontAwesomeIcon icon={faUser} className="me-1" />
                    <span>Individual</span>
                  </>
                )}
                {notification?.status == 2 && (
                  <>
                    <FontAwesomeIcon icon={faUserGroup} className="me-1" />
                    <span>Group</span>
                  </>
                )}
              </div> */}

              {/* <div>
                <FontAwesomeIcon icon={faClock} className="me-1" />
                <span className="fw-bold me-1">
                  {convertTimeToHumanTime(notification?.created_at) || ''}
                </span>
                {convertTimestampToDate(notification?.created_at) || ''}
              </div> */}
            </p>
          </div>
        </div>
        <RevealDropdown
          className={classNames('', {
            'd-none d-sm-block': type === 'dropdownItem'
          })}
          btnClassName="notification-dropdown-toggle"
          dropdownMenuClassName="mt-2"
        >
          <Dropdown.Item
            onClick={() => {
              handleSubmit(notification);
            }}
          >
            Mark as {notification?.is_seen ? 'unread' : 'read'}
          </Dropdown.Item>
        </RevealDropdown>
      </div>
    </div>
  );
};

export default NotificationItem;
