import React from 'react';
import logo from 'assets/img/logo.png';
import classNames from 'classnames';

interface LogoProps {
  width?: number;
  text?: boolean;
  textClass?: string;
  className?: string;
}

const Logo = ({ width = 170, className }: LogoProps) => {
  return (
    <div
      className={classNames(className, 'icms-logo d-flex align-items-center')}
    >
      <img src={logo} alt="phoenix" width={width} />
    </div>
  );
};

export default Logo;
