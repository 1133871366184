import { Dropdown, Nav } from 'react-bootstrap';
import Avatar from 'components/base/Avatar';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import React, { ChangeEvent, useState } from 'react';
import { checkScope } from '../../../helpers/auth';
import { useTranslation } from 'react-i18next';
import avatar from 'assets/img/team/72x72/57.webp';
import { changeLanguage } from '../../../i18n';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import useAuthHook from 'hooks/modules/useAuthHook';
import FeatherIcon from 'feather-icons-react';

const NavItems = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const { user } = useAuthHook();

  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const handleChangeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;
    changeLanguage(language);
  };

  const imageUrl = React.useMemo(() => {
    const storageEndpoint = `${process.env.REACT_APP_ENDPOINT_STORAGE}`;
    return user?.image ? `${storageEndpoint}/${user?.image}` : avatar;
  }, [user]);

  return (
    <div className="navbar-nav navbar-nav-icons flex-row align-items-center">
      <div className="add-new-case">
        {checkScope('pre-intake-create') && (
          <Button
            onClick={() => {
              navigate('/pre-intake/add');
            }}
            variant="subtle-primary"
            className="rounded-pill px-3"
          >
            <FontAwesomeIcon icon={faAdd} className="me-1" />
            {t('add_new_case')}
          </Button>
        )}
      </div>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <Link
              to="#"
              className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            >
              <FeatherIcon icon="bell" size="18" />
            </Link>
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item>
      <Nav.Item className="me-1">
        <select
          onChange={handleChangeLanguage}
          defaultValue={i18n.language}
          className="border-0"
        >
          <option value="en">EN</option>
          <option value="km">KM</option>
        </select>
      </Nav.Item>
      <Nav.Item>
        <Dropdown
          autoClose="outside"
          className="h-100"
          show={showDropdown}
          onToggle={isOpen => setShowDropdown(isOpen)}
        >
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={imageUrl || ''} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu handleToggle={() => setShowDropdown(false)} />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
