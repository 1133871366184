import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  return (
    <footer className={classNames(className, 'footer')}>
      <Row className="g-1 justify-content-center align-items-center h-100">
        <Col
          xs={12}
          sm="auto"
          className="text-center d-flex gap-2 align-items-center"
        >
          <p className="mb-0 mt-2 mt-sm-0 fs-9">
            {new Date().getFullYear()} &copy; {t('copyright_isscms_2024')} {'.'}
            {t('all_rights_reserved')}
          </p>
          <Link className="text-success fs-9" to={'/'}>
            {t('term_and_agreement')}
          </Link>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
