export const onBehalfArray = [
  {
    label: 'Myself',
    value: 'myself'
  },
  {
    label: 'Others',
    value: 'others'
  },
  {
    label: 'Group',
    value: 'group'
  }
];
export const genders = [
  {
    label: 'male',
    value: 'M'
  },
  {
    label: 'female',
    value: 'F'
  }
];

export const ageRanges = [
  {
    label: 'old',
    value: 'old'
  },
  {
    label: 'adult',
    value: 'adult'
  },
  {
    label: 'child',
    value: 'child'
  }
];

export const statuses = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'InActive',
    value: 'in_active'
  }
];
export const recipients = [
  {
    label: 'Individual',
    value: 1
  },
  {
    label: 'Family',
    value: 2
  },
  {
    label: 'Group',
    value: 3
  }
];

export const flags = [
  {
    shortLabel: 'IN',
    label_translation_key: 'incoming',
    value: 1
  },
  {
    shortLabel: 'SP',
    label_translation_key: 'sent_to_sp',
    value: 2
  },
  {
    shortLabel: 'AC',
    label_translation_key: 'accepted_by_sp',
    value: 3
  },
  {
    shortLabel: 'RA',
    label_translation_key: 'reassessment',
    value: 4
  },
  {
    shortLabel: 'RE',
    label_translation_key: 'returned',
    value: 5
  },
  {
    shortLabel: 'GR',
    label_translation_key: 'grievance',
    value: 5
  },
  {
    shortLabel: 'PR',
    label_translation_key: 'priority',
    value: 6
  },
  {
    shortLabel: 'CC',
    label_translation_key: 'closed',
    value: 7
  }
];
