import api from '../../utils/api';
import { TLocation } from '../../types/mdm';
import axios from 'axios';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/mdm/location`;

const fetchAll = (parentId?: number) => {
  if (parentId) {
    return axios.get(`${authEndpoint}?parent_id=${parentId}`);
  } else {
    return axios.get(`${authEndpoint}`);
  }
};
const fetchAllWithTreeStructure = () => {
  return axios.get(`${authEndpoint}/tree`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};
const create = (data: TLocation) => {
  return api.post(`${authEndpoint}`, data);
};
const update = (id: number, data: TLocation) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchAllWithTreeStructure,
  fetchOne,
  create,
  update,
  destroy
};
