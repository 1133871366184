import * as Yup from 'yup';
import i18next from 'i18next';
// eslint-disable-next-line
const t: any = i18next.t; // Directly access the translation function
export const UserUpdateSchema = () => {
  // eslint-disable-next-line

  return Yup.object().shape({
    image: Yup.mixed()
      .notRequired()
      .nullable()
      .test('fileSize', t('profile_picture_maximum_file_size'), value => {
        if (!value) return true;
        const file = value as File;
        return file.size <= 5 * 1024 * 1024;
      })
      .test('fileType', t('profile_picture_valid_file_type'), value => {
        if (!value) return true;
        const file = value as File;
        return ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
      }),
    name: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('name')
        })
      )
      // .min(
      //   6,
      //   t('form_validation_min', {
      //     field: t('name'),
      //     min: '6'
      //   })
      // )
      .max(
        50,
        t('form_validation_max', {
          field: t('name'),
          max: '50'
        })
      ),
    email: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('email')
        })
      )
      .email('form_validation_invalid_email')
  });
};

export const UserChangePasswordSchema = () => {
  // eslint-disable-next-line

  return Yup.object().shape({
    password: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('password')
      })
    ),
    new_password: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('new_password')
        })
      )
      .min(
        6,
        t('form_validation_min', {
          field: t('new_password'),
          min: '6'
        })
      ),
    confirm_password: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('confirm_password')
        })
      )
      .min(
        6,
        t('form_validation_min', {
          field: t('confirm_password'),
          min: '6'
        })
      )
      .oneOf([Yup.ref('new_password')], t('form_validation_match_password'))
  });
};

export const SignInSchema = () => {
  // eslint-disable-next-line

  return Yup.object().shape({
    username: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('username')
      })
    ),
    password: Yup.string()
      .min(
        6,
        t('form_validation_min', {
          field: t('password'),
          min: '6'
        })
      )
      .required(
        t('form_validation_mandatory', {
          field: t('password')
        })
      )
  });
};

export const ForgotPasswordSchema = () => {
  // eslint-disable-next-line

  return Yup.object({
    to: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('email')
        })
      )
      .email('form_validation_invalid_email')
  });
};

export const ResetPasswordSchema = () => {
  // eslint-disable-next-line

  return Yup.object({
    password: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('password')
        })
      )
      .min(
        6,
        t('form_validation_min', {
          field: t('password'),
          min: '6'
        })
      ),
    confirm_password: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('confirm_password')
        })
      )
      .min(
        6,
        t('form_validation_min', {
          field: t('confirm_password'),
          min: '6'
        })
      )
      .oneOf([Yup.ref('password')], t('form_validation_match_password'))
  });
};
