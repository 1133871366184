import axios from 'axios';

import { getAuthFromLocalStorage } from '../utils/storage';
import api from '../utils/api';
import {
  TAuthRefreshToken,
  TAuthUser,
  TChangePassword,
  TForgotPassword,
  TLogin,
  TResetPassword
} from '../types/auth';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/api/auth`;

const login = (data: TLogin) => {
  data.client_id = `${process.env.REACT_APP_CLIENT_ID}`;
  data.client_secret = `${process.env.REACT_APP_CLIENT_SECRET}`;
  return axios.post(`${authEndpoint}/login`, data);
};
const forgotPassword = (data: TForgotPassword) => {
  return axios.post(`${authEndpoint}/request-password-reset`, data);
};
const resetPassword = (data: TResetPassword) => {
  return axios.post(`${authEndpoint}/password-reset`, data);
};
const changePassword = (data: TChangePassword) => {
  return api.post(`${authEndpoint}/tenants/myProfile/changePassword`, data);
};
const updateProfile = (data: TAuthUser) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      formData.append(key, value);
    }
  });

  return api.post(`${authEndpoint}/tenants/myProfile/updateProfile`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
const refreshToken = (data: TAuthRefreshToken) => {
  data.client_id = `${process.env.REACT_APP_CLIENT_ID}`;
  data.client_secret = `${process.env.REACT_APP_CLIENT_SECRET}`;
  return axios.post(`${authEndpoint}/refresh`, data);
};
const logout = () => {
  const auth = getAuthFromLocalStorage();
  return axios.post(
    `${authEndpoint}/logout`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + auth.token //the token is a variable which holds the token
      }
    }
  );
};

const AuthService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  updateProfile,
  refreshToken,
  changePassword
};
export default AuthService;
