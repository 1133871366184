import api from '../../utils/api';
import {
  TCase,
  TFilterCase,
  TIDVerification,
  TTrackCase
} from '../../types/cases';
import { serializedObject } from '../../helpers/utils';
import axios from 'axios';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case`;

const fetchAll = (filters: TFilterCase) => {
  const { flags, ...filteredFilters } = filters;
  return api.get(
    `${authEndpoint}?${serializedObject(filteredFilters)}${flags && `&flags[]=${flags}`}`
  );
};
const fetchOne = (id: string) => {
  return api.get(`${authEndpoint}/${id}`);
};
const create = (data: TCase) => {
  return api.post(`${authEndpoint}`, data);
};
const update = (id: number, data: TCase) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};
const trackCase = (data: TTrackCase) => {
  return axios.post(`${authEndpoint}/track-case`, data);
};
const fetchCount = () => {
  return api.get(`${authEndpoint}/getCount`);
};
const fetchIdVerification = (data: TIDVerification) => {
  return api.post(`${authEndpoint}/id-verification`, data);
};
const fetchFlags = () => {
  return api.get(`${authEndpoint}/getFlags`);
};

export default {
  trackCase,
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  fetchCount,
  fetchIdVerification,
  fetchFlags
};
