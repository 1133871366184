import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { TPreIntake } from '../../../../types/intake';
import useLocationHook from '../../../../hooks/modules/mdm/useLocationHook';
import { TLocation } from '../../../../types/mdm';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useCookies from '../../../../hooks/useCookiesHook';

interface PreIntakeLocationFormProps {
  preIntake?: TPreIntake;
}

const PreIntakeLocationForm = ({ preIntake }: PreIntakeLocationFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { isEnglishLanguage } = useCookies();
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<TPreIntake>();
  const { fetchAllLocation, locations } = useLocationHook();
  useEffect(() => {
    // fetchAllLocation().catch(e => console.log(e));
    if (locations.length <= 0) {
      fetchAllLocation().catch(e => console.log(e));
    }
  }, []);
  const [locationState, setLocationState] = useState({
    provinces: [] as TLocation[],
    districts: [] as TLocation[],
    communes: [] as TLocation[],
    villages: [] as TLocation[]
  });
  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      provinces: locations.filter(data => !data.parent_id)
    }));
  }, [locations]);
  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      districts:
        locations.filter(data => data.parent_id == preIntake?.province) || []
    }));
  }, [preIntake?.province]);

  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      communes:
        locations.filter(data => data.parent_id == preIntake?.district) || []
    }));
  }, [preIntake?.district]);
  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      villages:
        locations.filter(data => data.parent_id == preIntake?.commune) || []
    }));
  }, [preIntake?.commune]);
  const updateLocation = (
    id: string,
    parentLevel: keyof typeof locationState,
    childLevel: keyof typeof locationState
  ) => {
    const children = id
      ? locations?.filter(data => data.parent_id === parseInt(id))
      : [];

    setLocationState(prevState => ({
      ...prevState,
      [childLevel]: children,
      ...(childLevel === 'provinces' && {
        districts: [],
        communes: [],
        villages: []
      }),
      ...(childLevel === 'districts' && { communes: [], villages: [] }),
      ...(childLevel === 'communes' && { villages: [] })
    }));
  };

  return (
    <Row>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupProvince">
          <Form.Label>{t('province')}</Form.Label>
          <Form.Select
            value={values?.province || ''}
            name="province"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('province', selectedId);
              updateLocation(selectedId, 'provinces', 'districts');
            }}
            className={`${
              touched.province && errors.province ? 'is-invalid' : ''
            }`}
          >
            <option value="">{t('select_province')}</option>

            {locationState.provinces.map((data, i) => (
              <option key={`province-${i}`} value={data.id as number}>
                {`${isEnglishLanguage ? data.name : data.name_alt || data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
          {touched.province && errors.province && (
            <Form.Control.Feedback type="invalid">
              {errors.province}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupDistrict">
          <Form.Label>{t('district')}</Form.Label>
          <Form.Select
            value={values?.district || ''}
            name="district"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('district', selectedId);
              updateLocation(selectedId, 'districts', 'communes');
            }}
            className={`${
              touched.district && errors.district ? 'is-invalid' : ''
            }`}
          >
            <option value="">{t('select_district')}</option>
            {locationState.districts.map((data, i) => (
              <option key={`district-${i}`} value={data.id as number}>
                {`${isEnglishLanguage ? data.name : data.name_alt || data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
          {touched.district && errors.district && (
            <Form.Control.Feedback type="invalid">
              {errors.district}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupCommune">
          <Form.Label>{t('commune_optional')}</Form.Label>
          <Form.Select
            value={values?.commune || ''}
            name="commune"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('commune', selectedId);
              updateLocation(selectedId, 'communes', 'villages');
            }}
          >
            <option value="">{t('select_commune')}</option>
            {locationState.communes.map((data, i) => (
              <option key={`commune-${i}`} value={data.id as number}>
                {`${isEnglishLanguage ? data.name : data.name_alt || data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupVillage">
          <Form.Label>{t('village_optional')}</Form.Label>
          <Form.Select
            value={values?.village || ''}
            name="village"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('village', selectedId);
            }}
          >
            <option value="">{t('select_village')}</option>
            {locationState.villages.map((data, i) => (
              <option key={`village-${i}`} value={data.id as number}>
                {`${isEnglishLanguage ? data.name : data.name_alt || data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default PreIntakeLocationForm;
