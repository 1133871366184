import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import Button from 'components/base/Button';
import NotificationItem from 'components/common/NotificationItem';
import classNames from 'classnames';
import useNotificationHook from 'hooks/modules/useNotification';
import { TFilterNotification } from 'types/notification';
import useAuthHook from 'hooks/modules/useAuthHook';
import PhoenixLoader from 'components/common/PhoenixLoader';

const initFilter: TFilterNotification = {
  page: 1,
  limit: 10,
  type: 'bell',
  user_id: 0
};

const NotificationDropdownMenu = ({ className }: { className?: string }) => {
  const { user } = useAuthHook();
  const { notifications, fetchAllNotification, updateNotificationSeenStatus } =
    useNotificationHook();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [filter, setFilter] = React.useState<TFilterNotification>(initFilter);

  const handleSubmit = () => {
    notifications?.map(notification => {
      const data = { is_seen: true };
      updateNotificationSeenStatus(notification?.id || 0, data).catch(
        (e: unknown) => console.log(e)
      );
    });
  };

  React.useEffect(() => {
    setLoading(true);
    setFilter(prevData => ({
      ...prevData,
      user_id: user?.user_role?.pivot?.user_id || 0
    }));
    if (filter?.user_id) {
      fetchAllNotification(filter)
        .catch((e: unknown) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [JSON.stringify(filter)]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-dropdown-caret py-0 notification-dropdown-menu shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Header className="p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-body-emphasis mb-0">Notifications</h5>
            <Button
              variant="link"
              className="p-0 fs-9 fw-normal"
              onClick={() => handleSubmit()}
            >
              Mark all as read
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-0" style={{ height: '16rem' }}>
          <Scrollbar>
            {!loading ? (
              <>
                {notifications?.length >= 1 ? (
                  notifications?.slice(0, 10)?.map((notification, index) => (
                    <NotificationItem
                      notification={notification}
                      type="dropdownItem"
                      className={classNames({
                        'border-bottom border-translucent':
                          index !== notifications?.length - 1
                      })}
                      key={notification?.id}
                    />
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center w-100 h-100">
                    No notifications
                  </div>
                )}
              </>
            ) : (
              <PhoenixLoader />
            )}
          </Scrollbar>
        </Card.Body>
        {/* <Card.Footer className="p-0 border-top">
          <div className="my-2 text-center fw-bold fs-10 text-body-tertiary text-opactity-85">
            <Link to="/pages/notifications" className="fw-bolder">
              Notification history
            </Link>
          </div>
        </Card.Footer> */}
      </Card>
    </Dropdown.Menu>
  );
};

export default NotificationDropdownMenu;
