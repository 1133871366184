import * as Yup from 'yup';
import i18next from 'i18next';
// eslint-disable-next-line
const t: any = i18next.t; // Directly access the translation function

export const PreIntakeSchema = () => {
  return Yup.object().shape({
    on_behalf: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('on_behalf')
      })
    ),
    individual_first_name: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('first_name')
        })
      )
      .max(
        50,
        t('form_validation_max', {
          max: 50,
          field: t('first_name')
        })
      ),
    individual_last_name: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('last_name')
        })
      )
      .max(
        50,
        t('form_validation_max', {
          max: 50,
          field: t('last_name')
        })
      ),
    individual_phone_number: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('phone_number')
        })
      )
      .matches(
        /^[0-9]+$/,
        t('form_validation_numeric', { field: 'phone_number' })
      )
      .max(10, t('form_validation_max', { max: 10, field: 'phone_number' })),
    individual_address: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('address')
      })
    ),
    // individual_email: Yup.string()
    //   .email(t('form_validation_invalid_email'))
    //   .required(
    //     t('form_validation_mandatory', {
    //       field: t('email')
    //     })
    //   ),
    province: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('province')
      })
    ),
    district: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('district')
      })
    ),
    reason_for_apply: Yup.string()
      .max(
        255,
        t('form_validation_max', {
          max: 255,
          field: t('reason_for_applying')
        })
      )
      .notRequired()
      .nullable()
  });
};

export const PreIntakeValidationSchema = () => {
  return Yup.object().shape({
    decision: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('decision')
      })
    ),

    title: Yup.string().when('decision', {
      is: (decision: string | undefined) => decision === 'save_to_history',
      then: schema =>
        schema.required(
          t('form_validation_mandatory', {
            field: t('title')
          })
        )
    }),
    description: Yup.string().when('decision', {
      is: (decision: string | undefined) => decision === 'save_to_history',
      then: schema =>
        schema.required(
          t('form_validation_mandatory', {
            field: t('description')
          })
        )
    }),
    findings: Yup.string().when('decision', {
      is: (decision: string | undefined) => decision !== 'save_to_history',
      then: schema =>
        schema.required(
          t('form_validation_mandatory', {
            field: t('findings')
          })
        )
    }),
    conclusion: Yup.string().when('decision', {
      is: (decision: string | undefined) => decision !== 'save_to_history',
      then: schema =>
        schema.required(
          t('form_validation_mandatory', {
            field: t('conclusion')
          })
        )
    })
  });
};
export const TrackCaseSchema = () => {
  return Yup.object().shape({
    pre_intake_case_id: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('pre_intake_case_id')
      })
    ),
    individual_first_name: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('first_name')
      })
    )
  });
};

export const ServiceProviderSchema = () => {
  return Yup.object({
    name: Yup.string()
      .required(
        t('form_validation_mandatory', {
          field: t('name')
        })
      )
      .max(180, t('form_validation_max', { field: t('name'), max: 180 })),
    name_alt: Yup.string()
      .max(180, t('form_validation_max', { field: t('name_alt'), max: 180 }))
      .notRequired()
      .nullable(),
    service_type: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('service_provider') + ' ' + t('type')
      })
    ),
    status: Yup.string().required(
      t('form_validation_mandatory', {
        field: t('status')
      })
    ),
    service_type_ids: Yup.array()
      .min(1, t('form_validation_mandatory', { field: t('service_type') }))
      .required(t('form_validation_mandatory', { field: t('service_type') })),
    phone_number: Yup.string()
      .matches(
        /^[0-9]+$/,
        t('form_validation_numeric', { field: t('phone_number') })
      )
      .max(10, t('form_validation_max', { max: 10, field: t('phone_number') }))
      .notRequired()
      .nullable(),
    alternative_phone_number: Yup.string()
      .matches(
        /^[0-9]+$/,
        t('form_validation_numeric', {
          field: t('alternate') + ' ' + t('number')
        })
      )
      .max(
        10,
        t('form_validation_max', {
          max: 10,
          field: t('alternate') + ' ' + t('number')
        })
      )
      .notRequired()
      .nullable(),
    registered_date: Yup.date()
      .max(new Date(), t('future_dates_not_allowed'))
      .notRequired()
      .nullable(),
    email: Yup.string()
      .email(t('form_validation_invalid_email'))
      .required(
        t('form_validation_mandatory', {
          field: t('email')
        })
      )
  });
};
