import { Navbar } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItemsSlim from 'components/navbars/nav-items/NavItemsSlim';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavbarTopNav from './NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import {
  DashboardSquare01Icon,
  Database01Icon,
  FolderCheckIcon
} from 'hugeicons-react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkScope } from '../../../helpers/auth';
import { UilCog, UilGlobe } from '@iconscout/react-unicons';

const NavbarTopHorizontal = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarTopShape,
      navbarTopAppearance
    }
  } = useAppContext();

  const { breakpoints } = useBreakpoints();
  const { pathname } = useLocation();

  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-slim': navbarTopShape === 'slim'
      })}
      expand="lg"
      variant=""
      data-navbar-appearance={navbarTopAppearance === 'darker' ? 'darker' : ''}
    >
      <NavbarBrand />
      {!(navbarPosition === 'combo' && breakpoints.down('lg')) && (
        <Navbar.Collapse
          className="navbar-top-collapse order-1 order-lg-0 pb-0"
          in={openNavbarVertical}
        >
          <div className="navbar-nav-top pb-4 pb-lg-0 scrollbar navbar-nav">
            <div className="nav-item">
              <Link
                to="/dashboard"
                className={`nav-link fw-semibold d-inline-flex align-items-center ${pathname == '/dashboard' ? 'text-primary' : ''}`}
              >
                <DashboardSquare01Icon className="me-2" size={15} />
                {t('dashboard')}
              </Link>
            </div>
          </div>
          <NavbarTopNav />
          <div className="navbar-nav-top pb-4 pb-lg-0 scrollbar navbar-nav">
            {checkScope('service-provider-list') && (
              <div className="nav-item">
                <Link
                  to="/service-providers"
                  className={`nav-link fw-semibold d-inline-flex align-items-center ${pathname == '/service-providers' ? 'text-primary' : ''}`}
                >
                  <Database01Icon className="me-2" size={15} />
                  {t('service_providers')}
                </Link>
              </div>
            )}

            {checkScope('complaint-list') && (
              <div className="nav-item">
                <Link
                  to="/issues"
                  className={`nav-link fw-semibold d-inline-flex align-items-center ${pathname == '/issues' ? 'text-primary' : ''}`}
                >
                  <FolderCheckIcon className="me-2" size={15} />
                  {t('issues')}
                </Link>
              </div>
            )}

            {/*{checkScope('setting-list') && (*/}
            {/*  <Link*/}
            {/*    to="/mdm/location"*/}
            {/*    className={`nav-link fw-semibold d-inline-flex align-items-center ${pathname == '/setting' ? 'text-primary' : ''}`}*/}
            {/*  >*/}
            {/*    <UilMapMarker className="me-2" size={15} />*/}
            {/*    {t('location')}*/}
            {/*  </Link>*/}
            {/*)}*/}
            {checkScope('language-list') && (
              <div className="nav-item">
                <Link
                  to="/translation"
                  className={`nav-link fw-semibold d-inline-flex align-items-center ${pathname == '/translation' ? 'text-primary' : ''}`}
                >
                  <UilGlobe className="me-2" size={15} />
                  {t('language')}
                </Link>
              </div>
            )}
            {checkScope('setting-list') && (
              <div className="nav-item">
                <Link
                  to="/setting"
                  className={`nav-link fw-semibold d-inline-flex align-items-center ${pathname == '/setting' ? 'text-primary' : ''}`}
                >
                  <UilCog className="me-2" size={15} />
                  {t('setting')}
                </Link>
              </div>
            )}
          </div>
        </Navbar.Collapse>
      )}
      {navbarTopShape === 'default' ? <NavItems /> : <NavItemsSlim />}
    </Navbar>
  );
};

export default NavbarTopHorizontal;
